import React from 'react'
import { AboutSection, HomeSection, Services, Banner } from '../../component'
import MetaData from '../MetaData'
import './Home.css'


const Home = () => {
  return (
    <>
      <MetaData title="Al-Wahid Quran Academy" />
      <HomeSection />
      <AboutSection />
      <Services />
      <Banner />
    </>
  )
}

export default Home