import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { BreadCrumb, Button, Heading, Loader } from '../../component'
import MetaData from '../MetaData'
import axios from 'axios'
import "./CourseDetail.css"

const CourseDetail = () => {
  const params = useParams()
  // console.log("id", params.id)
  const id = params.id
  // const URL = "http://localhost:8000"
  const URL = "https://al-wahid-server.netlify.app/.netlify/functions/server"
  const [data, setData] = useState([])
  const [file, setFile] = useState("");

  const getSingleCourse = async () => {
    await axios.get(`${URL}/single-course/${id}`)
      .then((response) => {
        // console.log(response.data)
        setData(response.data.course)
        setFile(response.data.course.image.myFile)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  useEffect(() => {
    getSingleCourse()
    // eslint-disable-next-line
  }, [params])
  const { description, title } = data
  return (
    <>
      <MetaData title='Course Detail' />
      <BreadCrumb heading={title} />
      {
        data.length === 0 ? <Loader className = "main_container_loader-margin" /> : (
          <div className="main_container_course_detail_section">
            <div className="main_container_course_detail_section_content">
              <div className="main_container_course_detail_section_content_img">
                <img alt={title} src={file} className="course_img" />
              </div>
              <div className="main_container_course_detail_section_content_heading">
                <Heading heading={title} className="main_heading-black" />
              </div>
              <div className="main_container_course_detail_section_content_desc">
                <h5 className='main_container_course_detail_section_content_desc-text'>{description}</h5>
              </div>
              <div className='main_container_course_detail_section_content-btn'><Link to="/registration-page" className='card-content-btn'><Button title="Enroll Now!" /></Link></div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default CourseDetail