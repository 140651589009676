import React from 'react'
import MetaData from '../MetaData'
import { Link } from 'react-router-dom'
import { BreadCrumb, SectionHeading } from '../../component'
import "./NotFoundPage.css"
const NotFoundPage = () => {
  return (
    <>
      <MetaData title="Al-Wahid Quranic Academy - Page Not Found" />
      <BreadCrumb heading="404 Error Page" />
      <div className="main_container_notfound">
        <h1>4<span>0</span>4</h1>
        <h2>Error!</h2>
        <SectionHeading title = 'Page Not Found..!!' />
        <h5>It seems we cant find what you are looking for. Perhaps searching can help or go <Link to = "/" className='main_container_notfound-link'>Al-Wahid Quranic Academy</Link> </h5>
      </div>
    </>
  )
}

export default NotFoundPage