import React from 'react'
import MetaData from '../MetaData'
import {BreadCrumb, RegistrationForm} from '../../component'


const Registration = () => {
  return (
    <>
      <MetaData title = "Al-Wahid Quranic Academy - Registration" />
      <BreadCrumb  heading = "Register Yourself" />
      <RegistrationForm heading = "Enroll Now!"  />
    </>
  )
}

export default Registration