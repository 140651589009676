import { HiAcademicCap } from 'react-icons/hi'
import { FaChild, FaUserGraduate } from 'react-icons/fa'
import { SiGoogleclassroom } from 'react-icons/si'

const serviceData = [
    {
        icon: <FaChild />,
        title: "Courses for Children",
        desc: "We offer a comprehensive range of courses in Islamic education tailored specifically for children, including instruction in Hadith, Quran, and other essential Islamic teachings. Our programs are designed to impart a deep understanding of the principles and values of Islam, as well as to foster a sense of cultural appreciation and community engagement. Through our commitment to excellence in education, we strive to help children develop the knowledge, skills, and confidence needed to become informed and engaged members of the Muslim community."
    },
    {
        icon: <FaUserGraduate />,
        title: "Courses for Adult",
        desc: "We are proud to offer an array of educational opportunities focused on Islamic teachings, specifically tailored to meet the needs of young learners. Our curriculum includes comprehensive courses in Hadith, Quran, and other fundamental aspects of Islam, all designed to foster a deep understanding and appreciation of these essential teachings. With a commitment to excellence in education and a dedication to supporting the spiritual and intellectual growth of our students, we provide a dynamic and engaging learning environment for children seeking to deepen their knowledge of Islam."
    },
    {
        icon: <HiAcademicCap />,
        title: "Academic Courses",
        desc: "In addition to our Islamic education programs, we are pleased to offer a wide range of academic courses designed to support students in their pursuit of higher education. Our course offerings include specializations in Urdu, English, Mathematics, and other essential subjects, providing students with the skills and knowledge necessary to excel in their chosen fields. Through our commitment to academic excellence and individualized attention, we strive to equip our students with the tools and resources they need to succeed in their academic and professional pursuits."
    },
    {
        icon: <SiGoogleclassroom />,
        title: "Three days free Classes",
        desc: "We are delighted to announce a special opportunity for all prospective students to experience our courses firsthand. For a limited time, we are offering a three-day free trial to all students interested in exploring our educational offerings. This trial period provides a unique opportunity to engage with our expert instructors, explore our curriculum, and gain valuable insight into the benefits of our programs. We encourage all interested students to take advantage of this exclusive offer and discover the many ways in which we can help support their educational and personal growth."
    },

]

export {
    serviceData
}