import React, { useState } from 'react'
import Heading from '../Heading/Heading'
import { toast } from 'react-toastify';
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { countriesData } from '../../data/countriesData';
import { Loader } from '../index'
import "./ContactForm.css"


const ContactForm = (props) => {
    // const URL = "http://localhost:8000/api/v1"
    const URL = "https://al-wahid-server.netlify.app/.netlify/functions/server"
    const [name, setName] = useState("")
    const [studentAge, setStudentAge] = useState("")
    const [country, setCountry] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()


    const sendForm = async (e) => {
        e.preventDefault();
        await axios.post(`${URL}/contact-form-submit`, {
            name, email, studentAge, country, message
        }).then((response) => {
            // console.log(response.data)
            toast.success('Thank you for contacting us. We will get back to you soon', {
                position: toast.POSITION.TOP_RIGHT
            })
            setLoading(true)
            setName("")
            setEmail("")
            setCountry("")
            setStudentAge("")
            setMessage("")
            navigate("/thank-you")
        })
            .catch((e) => {
                // console.log(e.response.data.message);
                setLoading(false)
                toast.error(`${e.response.data.message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
    }
    return (
        <>
            <section id='contact'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-md-11 col-ml-8 pt-5 pt-lg-5 order-2 order-lg-1 margin-set contact-box-shodow '>
                                    <Heading heading={props.heading} />
                                    <form>
                                        <div className='feedback__form '>
                                            <input type="text" name='name' placeholder="Enter Your Name" className="input-form" value={name} onChange={(e) => { setName(e.target.value) }} required />
                                            <input type="email" name='email' placeholder="Enter Your Email" className="input-form" value={email} onChange={(e) => { setEmail(e.target.value) }} required />
                                            <input type="number" name='student_age' placeholder="Enter Student Age" className="input-form" value={studentAge} onChange={(e) => { setStudentAge(e.target.value) }} required />
                                            
                                            <select className="input-form" name="country" id="country" value={country} onChange={(e) => { setCountry(e.target.value) }} required type='text'>
                                                {
                                                    countriesData.map((item, index) =>
                                                        <option value={item.name} key={index}>{item.name}</option>
                                                    )
                                                }
                                            </select>

                                            <textarea type='text' name='message' placeholder="Enter Your Message" className="input-form" value={message} onChange={(e) => { setMessage(e.target.value) }} required />

                                            <button type="submit" value="Send" className='contact_submit' onClick={sendForm}> { loading === false ? "Submit": <Loader className = "main_container_loader" / > } </button>
                                        </div>
                                    </form>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactForm