import React from 'react'
import MetaData from '../MetaData'
import { BreadCrumb, SectionHeading, Heading, Button } from '../../component'
import { Link } from 'react-router-dom'
import "./Thankyou.css"


const Thankyou = () => {
  return (
    <>
      <MetaData title="Al-Wahid Quranic Academy - Thank you Page" />
      <BreadCrumb heading="Thank you Page" />
      <div className = "main_container_thank_you">
        <div className = "main_container_thank_you-content">
        <Heading heading = "Thank You!" />
      <SectionHeading title = 'Thank you for contacting us. We will get back to you soon...' />
      <h5 className = "main_container_thank_you-content-h5">Your message just showed up in my inbox. Talk to you soon</h5>
     <div className = "main_container_thank_you-content-button" > <Link to = "/" className = "main_container_thank_you-content-button-link"><Button title = "Back to Home"  /></Link>

     </div>
        </div>
      </div>

    </>
  )
}

export default Thankyou