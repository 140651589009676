import React from 'react'
import "./SectionHeading.css"


const SectionHeading = (props) => {
    return (
        <>
           <h4 className='section_heading_h4' >
            {props.title}
           </h4>
        </>
    )
}

export default SectionHeading