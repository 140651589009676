import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BreadCrumb, Heading, Loader } from '../../component'
import axios from 'axios'
import "./BlogDetail.css"

const BlogDetail = () => {
    const params = useParams()
    const id = params.id
    // const URL = "http://localhost:8000"
    const URL = "https://al-wahid-server.netlify.app/.netlify/functions/server"
    const [data, setData] = useState([])
    const [file, setFile] = useState("");

    const getSingleBlog = async () => {
        await axios.get(`${URL}/get-single-blog/${id}`)
            .then((response) => {
                // console.log(response.data)
                setData(response.data.blog)
                setFile(response.data.blog.image.myFile)
            })
            .catch((e) => {
                console.log(e)
            })
    }
    useEffect(() => {
        getSingleBlog()
        // eslint-disable-next-line
    }, [params])
    const { description, title, tags, createdAt } = data

    function date(createdAt) { 
        var dt = new Date(createdAt);
        const currentMonth = dt.getMonth();
        const monthString = currentMonth >= 10 ? currentMonth : `0${currentMonth}`;
        const currentDate = dt.getDate();
        return `${dt.getFullYear()}-${monthString}-${currentDate}`;
    }

    return (
        <>
            <BreadCrumb heading={title} />
            {
                data.length === 0 ? <Loader className = "main_container_loader-margin" /> : (
                    <div className="main_container_blog_detail_section">
                        <div className="main_container_blog_detail_section_content">
                            <div className="main_container_blog_detail_section_content_img">
                                <img alt={title} src={file} className="blog_img" />
                            </div>
                            <div className="main_container_blog_detail_section_content_date">
                            <p className='main_container_blog_detail_section_content_date-date'>{date(createdAt)}</p>
                            </div>
                            <div className="main_container_blog_detail_section_content_heading">
                                <Heading heading={title} className="main_heading-black" />
                            </div>
                            <div className="main_container_blog_detail_section_content_desc">
                                <h5 className='main_container_blog_detail_section_content_desc-text'>
                                    <div dangerouslySetInnerHTML={{ __html: description }}></div>
                                </h5>
                            </div>
                            <div className='main_container_blog_detail_section_content_tags'>
                                {
                                    tags.map((tag, index) =>
                                        <p className="main_container_blog_detail_section_content_tags-p" key={index}>{tag}</p>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default BlogDetail