import React from 'react'
import { BreadCrumb, SectionHeading, Heading, ContactDetail } from '../../component'
import MetaData from '../MetaData'
import "./ContactPage.css"

const ContactPage = () => {
  return (
    <>
      <MetaData title="Al-Wahid Quran Academy - Contact" />
      <BreadCrumb
        heading="Contact Us"
      />
      <div className="main_container_contact">
        <div className="main_container_contact_content">

          <div className="main_container_contact_content-heading">
            <SectionHeading title="Have any Questions...!" />
            <Heading heading="Get In Touch" />
          </div>
          <ContactDetail />

        </div>
      </div>
    </>
  )
}

export default ContactPage