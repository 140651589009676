import React from 'react'
import SectionHeading from '../SectionHeading/SectionHeading'
import "./HomeSection.css"


const HomeSection = () => {
    return (
        <>
            <section className= "main_container_section">
                <div className="main_container_home">
                        <div className = "main_container_home_upper_image">
                        <img src = "/images/logo-png.png" alt = "home-section" className = "main_container_home_upper_image-img" />
                        </div>
                    <div className="main_container_home_content">
                        <div className="main_container_home_content-detail">
                            <div className="main_container_arabic-text">
                                &#65021;
                            </div>
                            <div className="main_container_home-heading">
                                Learn the Quran, for it will be an intercessor for you on the Day of Judgment
                            </div>
                            <div className="main_container_home_content-seperater"></div>
                            <div className="main_container_home_content-section-heading">
                                <SectionHeading title="Prophet Muhammad" />
                                <span className='main_container_home_content-section-heading-span'> &#xFDFA;</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeSection
