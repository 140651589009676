import React from 'react'
import {HiOutlineMail, HiOutlinePhone} from 'react-icons/hi'
import { BsInstagram } from 'react-icons/bs'
import {GrFacebookOption} from 'react-icons/gr'
import "./Information.css"


const Information = () => {
    return (
        <>
            <div className="main_container_info">
                <div className="main_container_info-flex">
                        <div className = "info-flex">
                            <div className = "info-icons"><HiOutlineMail /></div>
                            <strong>Email</strong><span>quranicacademyalwahid@gmail.com</span>
                        </div>
                        <div className = "info-flex">
                            <div className = "info-icons"><HiOutlinePhone /></div>
                            <strong>Phone</strong><span>+92335 2747132</span>
                        </div>
                        {/* <div className = "info-flex">
                           <div className = "info-icons"> <HiOutlineLocationMarker /></div>
                            <strong>Address</strong><span>19-J David Road H Block,</span><span>Orlando, USA</span>
                        </div> */}
                        <div className = "info-flex">
                            <div className = "info-icons"><GrFacebookOption /></div>
                            <strong>Facebook</strong><span>Al-Wahid Quranic Academy</span>
                        </div>
                        <div className = "info-flex">
                            <div className = "info-icons"><BsInstagram /></div>
                            <strong>Instagram</strong><span>alwahidqa</span>
                        </div>
                   
                </div>
            </div>
        </>
    )
}

export default Information