import React from 'react'
import { Link } from 'react-router-dom'
// import { BsFacebook } from 'react-icons/bs'
import Heading from '../Heading/Heading'
import './Footer.css'
const Footer = () => {
    return (
        <>
            <footer className="footer-section">
        <div className="container">
            <div className="footer-cta pt-5 pb-5">
                <div className="row">
                    <div className="col-xl-4 col-md-4 mb-30">
                        <div className="single-cta">
                            <i className="fas fa-map-marker-alt"></i>
                            <div className="cta-text">
                                <h4>Instagram</h4>
                                <span><a href = "https://www.instagram.com/alwahidqa/" className = "cta-text-span-a">alwahidqa</a></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 mb-30">
                        <div className="single-cta">
                            <i className="fas fa-phone"></i>
                            <div className="cta-text">
                                <h4>facebook</h4>
                                <span><a href = "https://www.facebook.com/HafizMuazzamAli99" className = "cta-text-span-a">Al-Wahid Quranic Academy</a></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 mb-30">
                        <div className="single-cta">
                            <i className="far fa-envelope-open"></i>
                            <div className="cta-text">
                                <h4>Mail us</h4>
                                <span>quranicacademyalwahid@gmail.com</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-content pt-5 pb-5">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 mb-50">
                        <div className="footer-widget">
                            <div className="footer-logo">
                                <Heading heading = "Al-Quran Wahid Academy" className = "main_heading-white" />
                            </div>
                            <div className="footer-text">
                                <p>Al Wahid Quranic Academy is a leading Virtual Quran and Islamic Studies Institute, which provides One on One Live class to students around the globe.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>Useful Links</h3>
                            </div>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about">About</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                                <li><Link to="/courses">Courses</Link></li>
                                <li><Link to="/blogs">Blogs</Link></li>
                                <li><Link to="/registration-page">Register Now</Link></li>
                            
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>Our Mission</h3>
                            </div>
                            <div className="footer-text mb-25">
                                <p>Al Wahid Quranic Academy's mission is to provide Quran and Islamic education, which is par excellence. We believe that with all of the support services and tools available, every student should learn Quran Recitation with Tajweed and Tarteel and aspire to learn the true meaning of Holy Quran.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-text">
<p>&copy; <Link to="/">Al-Wahid Quranic Academy</Link></p>
                        </div>
            </div>
        </div>
    </footer>

        </>
    )
}

export default Footer