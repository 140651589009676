import React from 'react';
import { Routes, Route } from 'react-router-dom'
import { Home, About, Courses, Registration, NotFoundPage, Thankyou, ContactPage, CourseDetail } from './pages/page'
import { AppNavbar, Footer,  } from '../src/component/index';
import { ToastContainer } from 'react-toastify';
// CSS File
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import BlogDetail from './pages/BlogDetail/BlogDetail';
import Blog from './pages/Blog/Blog';


function App() {
  return (
    <>
      <ToastContainer />
      <AppNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/registration-page" element={<Registration />} />
        <Route path="/thank-you" element={<Thankyou />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/course-detail/:id" element={<CourseDetail />} />
        <Route path="/blog-detail/:id" element={<BlogDetail />} />
        <Route path = "/blogs" element = {<Blog />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
