import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { Loader} from '../index'
import "./CourseCard.css"



const CourseCard = ({ courseData, loading }) => {
    const myStyle = {
        height: "200px",
        width: "286px"
    }
    return (

        <>
            {
                loading ? <Loader className = "main_container_loader-margin" /> : courseData.length === 0 ? (<>
<div> <h5>No Course yet...</h5> </div>
                </>):(
                    courseData.map((item, index) =>
                        <Card style={{ width: '18rem' }} className="course_card" key={index}>
                            <Card.Img variant="top" src={item.image.myFile} style={myStyle} />
                            <Card.Body>
                                <Card.Title className='course_card_content-heading'>{item.title.length > 15 ? `${item.title.substring(0, 15)}...` : item.title}</Card.Title>
                                <Card.Text className='course_card_content-text'>
                                    {item.description.length > 10 ?
                                        `${item.description.substring(0, 30)}...` : item.description
                                    }
                                </Card.Text>
                                <Link to={`/course-detail/${item._id}`} className='card-content-btn'>Read More!</Link>
                            </Card.Body>
                        </Card>
                    )
                )
            }
        </>
    )
}

export default CourseCard