import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import "./Banner.css"

const Banner = () => {
    return(
        <>
            <div className = "main_cotainer_banner">
                <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
                    <SwiperSlide>
                        <img  src = "./images/newBanner2.jpg" alt = "banner" className = "main_cotainer_banner-img" />
                    </SwiperSlide>
                    <SwiperSlide>
                    <img  src = "./images/newBanner1.jpg" alt = "banner1" className = "main_cotainer_banner-img"/>
                    </SwiperSlide>
                    <SwiperSlide>
                    <img  src = "./images/newBanner.jpg" alt = "banner2" className = "main_cotainer_banner-img"/>
                    </SwiperSlide>
                </Swiper>
            </div>
        </>
    )
}

export default Banner