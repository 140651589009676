import React from 'react'
import Heading from '../Heading/Heading'
import "./VisionMission.css"


const VisionMission = (props) => {
    return (
        <>
            <div className="main_container_home_vission_section">
                <div className="main_container_home_vission_section_content">
                    <div className="main_container_home_vission_section_vission">
                        <Heading
                            className="main_heading"
                            heading= {props.VisionHeading}
                        />
                        <h5 className='main_container_home_vission_section-text'>{props.VisionDesc}</h5>
                    </div>
                    <div className="main_container_home_vission_section_mission">
                        <Heading
                            className="main_heading"
                            heading={props.MissionHeading}
                        />
                        <h5 className='main_container_home_vission_section-text'>{props.MissionDesc}</h5>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VisionMission