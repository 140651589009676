import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { BlogCard } from '..'
import MyPagination from '../MyPagination/MyPagination'
import "./BlogCardGrid.css"

const BlogCardGrid = () => {
    // const URL = "http://localhost:8000/api/v1"
    const URL = "https://al-wahid-server.netlify.app/.netlify/functions/server"
    const [blogData, setBlogData] = useState([]);
    const [loading, setLoading] = useState(false);

    // For Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage] = useState(12);

    // get Blogs
    const getBlogs = async () => {
        setLoading(true)
        await axios.get(`${URL}/all-blogs`)
            .then((response) => {
                // console.log(response)
                setBlogData(response.data.blogs)
            })
            .catch((e) => {
                console.log("Getting blogs error", e)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    useEffect(() => {
        // eslint-disable-next-line
        getBlogs()
    }, [])

    // Reverse the Blog Data Element/Array
    const reverseBlog = blogData.reverse()
    // console.log("reverseBlog", reverseBlog)

    // Pagination
    const lastPostIndex = currentPage * postPerPage
    const firstPostIndex = lastPostIndex - postPerPage
    const currentPost = reverseBlog.slice(firstPostIndex, lastPostIndex)

    

    return (
        <>
            <div className='main_container_blog_card'>
                <BlogCard
                    blogData={currentPost}
                    loading={loading}
                />

            </div>
            <div className="main_container_blog_card_pagination">
            <MyPagination
                totalPosts={blogData.length}
                postPerPage={postPerPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
            />
            </div>

        </>
    )
}

export default BlogCardGrid