import React from 'react'
import MetaData from '../MetaData'
import { BreadCrumb, CourseCardGrid, Heading, SectionHeading } from '../../component'
import "./Courses.css"


const Courses = () => {
  return (
    <>
      <MetaData title="Al-Wahid Quranic Academy - Courses" />
      <BreadCrumb heading="Courses We Offer" />
      <div className="main_courses_component_heading">
        <Heading heading="Learn Everything from home" className = "main_courses_component_heading-heading"/>
        <SectionHeading title="We have started courses in our institution to make possible for students learn from home." className = "main_courses_component_heading-subheading" />
      </div>
      <CourseCardGrid />
      
    </>
  )
}

export default Courses