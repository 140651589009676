import React from 'react'
import { AboutSection, BreadCrumb, ChooseUs, VisionMission } from '../../component'
import "./About.css"

const About = () => {
  return (
    <>
     <BreadCrumb 
      heading = "About Us"
     />
     <AboutSection />
     <VisionMission
      MissionHeading = "Our Mission"
      VisionHeading = "Our Vision"
      VisionDesc = "Al Wahid Quranic Academy is devoted to the preparation of religious leaders. Our programs are designed with the young and mature learners in mind. We are not just a simple online school, we are committed to provide you complete online education. We are dedicated to provide consistent, high-quality and appropriate religious advising, monitoring, mentoring, nurturing and coaching to ensure your success. We believe that with all of the support services available, every student should learn Reciting Quran properly with Tajweed and even understand the true meanings of Quran"
    MissionDesc = "Al Wahid Quranic Academy's mission is to provide Quran and Islamic education, which is par excellence. We believe that with all of the support services and tools available, every student should learn Quran Recitation with Tajweed and Tarteel and aspire to learn the true meaning of Holy Quran. We believe student’s success depends on how well they take responsibility of their own education and it is up to them to take advantage of these services as soon as and as often as they need them."
    />
    <ChooseUs 
      desc = "3 days free trial to decide if you want to continue with us. You will be charged after the Free Trial. Our full time tutors who are highly skilled in Islamic Knowledge and fully trained. Class time are available 24/7 and can be chosen according to your time table. Fully interaction with the teacher using audio, video, and sharing of textbooks, Power Point , PDF documents and Flash books etc. Use of the high quality Hardware and high bandwidth for crystal clear audio and HD camera broadcast. Learning through Interactive games and activities."
    />
    </>
  )
}

export default About