import React from 'react'
import "./Heading.css"
import SectionPic from '../../pictures/sectionimg.png'

const Heading = (props) => {
    return (
        <>
            <div className="main_heading_container">
                <h1 className={`${props.className}`}> {props.heading} </h1>
                <img  src= {SectionPic} alt='heading-img' />
            </div>
        </>
    )
}

export default Heading