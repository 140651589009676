import React from 'react'
import MetaData from '../MetaData'
import { BreadCrumb, Heading, SectionHeading, BlogCardGrid } from '../../component'
import "./Blog.css"

const Blog = () => {
    return (
        <>
            <MetaData title="Al-Wahid Quranic Academy - Blogs" />
            <BreadCrumb heading="Our Blogs" />
            <div className="main_blogs_component_heading">
                <SectionHeading title="Our Writing" className="main_blogs_component_heading-subheading" />
                <Heading heading="Our Blogs" className="main_blogs_component_heading-heading" />
            </div>
            <BlogCardGrid />
        </>
    )
}

export default Blog