import React, { useState, useRef, useEffect} from 'react'
import Heading from '../Heading/Heading'
import { toast } from 'react-toastify';
import axios from 'axios';
import { Loader } from '../index'
import { useNavigate } from 'react-router-dom'
import { countriesData } from '../../data/countriesData';
import "./RegistrationForm.css"

const RegistrationForm = (props) => {
    // const URL = "http://localhost:8000"
    const URL = "https://al-wahid-server.netlify.app/.netlify/functions/server"
    const form = useRef();
    // UseStates for Form Submission
    const [studentName, setStudentName] = useState("");
    const [studentAge, setStudentAge] = useState("");
    const [studentGender, setStudentGender] = useState("");
    const [guardianName, setGuardianName] = useState("");
    const [guardianEmail, setGuardianEmail] = useState("");
    const [whatsAppNumber, setWhatsAppNumber] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [country, setCountry] = useState("");
    const [message, setMessage] = useState("");
    const [course, setCourse] = useState("");
    const [courseData, setCourseData] = useState([]);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    // get Courses
    const getCourses = async () => {
        await axios.get(`${URL}/all-courses`)
            .then((response) => {
                // console.log(response.data.Courses)
                setCourseData(response.data.Courses)
            })
            .catch((e) => {
                console.log("Getting course error", e)
            })
    }


    const submitForm = async (e) => {
        e.preventDefault();
        await axios.post(`${URL}/registration-form`, {
            studentName, studentAge, studentGender, guardianName, guardianEmail, whatsAppNumber, phoneNumber,
            country, message, course
        })
            .then((response) => {
                // console.log(response.data)
                toast.success('Thank you for enrollment. We will get back to you soon', {
                    position: toast.POSITION.TOP_RIGHT
                });
                setLoading(true)
                setStudentAge("")
                setStudentName("")
                setStudentGender("")
                setGuardianEmail("")
                setGuardianName("")
                setCountry("")
                setMessage("")
                setWhatsAppNumber("")
                setPhoneNumber("")
                navigate("/thank-you")
            })
            .catch((e) => {
                // console.log("Registration Error", e.response.data.message)
                setLoading(false)
                toast.error(`${e.response.data.message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
    }

    useEffect(() => {
        getCourses()
    }, [])
    return (
        <>

            <div>
                <section id='registration'>
                    <div className='registration_container-fluid'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-md-12 col-ml-12 col-lg-12 pt-5 pt-lg-5 order-2 order-lg-1 margin-set contact-box-shodow '>
                                        <div className="registration_container_heading"><Heading heading={props.heading} /></div>

                                        {
                                            courseData.length === 0 ? (
                                                <div className="form-loader">
                                                   <Loader className = "main_container_loader-margin"/>
                                                </div>
                                            ) : (
                                                <form ref={form} onSubmit={submitForm}>
                                                    <div className='registration_feedback__form '>
                                                        <input type="text" name='name' placeholder="Enter Student Name" className="registration_input-form" value={studentName} onChange={(e) => { setStudentName(e.target.value) }} required />
                                                        <input type="number" name='student_age' placeholder="Enter Student Age" className="input-form" value={studentAge} onChange={(e) => { setStudentAge(e.target.value) }} required />
                                                        <select className="input-form" name="gender" id="gender" value={studentGender} onChange={(e) => { setStudentGender(e.target.value) }} required>
                                                            <option value="" disabled>Select Gender</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                            <option value="Other">Other</option>
                                                        </select>
                                                        <input type="text" name='guardian' placeholder="Enter Guardian Name" className="registration_input-form" value={guardianName} onChange={(e) => { setGuardianName(e.target.value) }} required />
                                                        <input type="email" name='email' placeholder="Enter Your Email" className="input-form" value={guardianEmail} onChange={(e) => { setGuardianEmail(e.target.value) }} required />
                                                        <select className="input-form" name="course" id="course" value={course} onChange={(e) => { setCourse(e.target.value) }} required type='text'>
                                                        <option value={"Select the course"}>Select the Course</option>
                                                            {
                                                                courseData.length === 0 ? "Loading" : (
                                                                    courseData.map((item, index) =>
                                                                        <option value={item.length === 0 ? "Loading..." : item.title} key={index}>{item.title}</option>
                                                                    )
                                                                )
                                                            }
                                                        </select>
                                                        <input type="number" name='whatsapp' placeholder="Enter WhatsApp Number" className="input-form" value={whatsAppNumber} onChange={(e) => { setWhatsAppNumber(e.target.value) }} required />
                                                        <input type="number" name='phone' placeholder="Enter Phone Number" className="input-form" value={phoneNumber} onChange={(e) => { setPhoneNumber(e.target.value) }} required />
                                                        <select className="input-form" name="country" id="country" value={country} onChange={(e) => { setCountry(e.target.value) }} required type='text'>
                                                            {
                                                                countriesData.map((item, index) =>
                                                                    <option value={item.name} key={index}>{item.name}</option>
                                                                )
                                                            }
                                                        </select>


                                                        <textarea type='text' name='message' placeholder="Enter Your Message" className="input-form" value={message} onChange={(e) => { setMessage(e.target.value) }} required />
                                                        <button type="submit" value="Send" className='registration_submit'>{ loading === false ? "Submit": <Loader className = "main_container_loader" / > }</button>
                                                    </div>
                                                </form>

                                            )
                                        }
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default RegistrationForm

