import React, { useState } from "react";
import { Navbar, Container, Nav, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BiMenu } from 'react-icons/bi'
import './AppNavbar.css'

function AppNavbar() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [navbar, setNavbar] = useState(false)
    const [NavUI, setNavUI] = useState(false);

    const handleNavClick = () => {
        setShow(false);
      };

    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true)
            setNavUI(true)
        }
        else {
            setNavbar(false)
            setNavUI(false)
        }
    }

    window.addEventListener('scroll', changeBackground)
    return (
        <React.Fragment>
            <div className={navbar ? "main-navbar active" : "main_navbar"}>
                <Navbar scrolling expand="lg" fixed="top"  >
                    <Container>
                        <Link to="/" className="navbar-brand title__color">Al-Wahid Quran Academy</Link>
                        <button onClick={handleShow} className="mobile__navbar"  >
                            <BiMenu onClick={handleShow} className="me-2" />
                        </button>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" >
                            <Nav className="ms-auto nav-font">

                                <Link to='/' className={NavUI ? "NavUl active" : "NavUl"} >Home</Link>
                                <Link to='/about' className={NavUI ? "NavUl active" : "NavUl"}>About</Link>
                                <Link to='/courses' className={NavUI ? "NavUl active" : "NavUl"}>Courses</Link>
                                <Link to='/blogs' className={NavUI ? "NavUl active" : "NavUl"}>Blogs</Link>
                                <Link to='/registration-page' className={NavUI ? "NavUl active" : "NavUl"}>Registration Form</Link>
                                <Link to='/contact' className={NavUI ? "NavUl active" : "NavUl"}>Contact</Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
            <Offcanvas show={show}
                onHide={handleClose}
                placement="end"
                name="end"
                className="mobile_version_offcanvas_nav"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="mobile_version_offcanvas_nav-title">
                        <Link to="/" className="mobile_version_offcanvas_nav-title-link">Al-Wahid Quran Academy</Link>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>


                    <div className='mobile_version_links'>
                        <Link to='/' className="NavUl" onClick={handleNavClick}>Home</Link>
                        <Link to='about' className="NavUl" onClick={handleNavClick}>About</Link>
                        <Link to='courses' className="NavUl" onClick={handleNavClick}>Courses</Link>
                        <Link to='/blogs' className="NavUl" onClick={handleNavClick}>Blogs</Link>
                        <Link to='registration-page' className="NavUl" onClick={handleNavClick}>Registration Form</Link>
                        <Link to='contact' className="NavUl" onClick={handleNavClick}>Contact</Link>
                    </div>
                    



                </Offcanvas.Body>
            </Offcanvas>

        </React.Fragment>
    )
}

export default AppNavbar;