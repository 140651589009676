import React from 'react'
import ContactForm from '../ContactForm/ContactForm'
import Heading from '../Heading/Heading'
import SectionHeading from '../SectionHeading/SectionHeading'
import "./ChooseUs.css"

const ChooseUs = (props) => {
    return (
        <>
            <div className="main_container_choose_section">
                <div className="main_container_choose_section_content">
                    <div className="main_container_choose_section_left">
                        <SectionHeading title="Our Advantages" />
                        <Heading heading="Why Choose Us?" />
                        <h5 className="main_container_choose_section_left-text">{props.desc}</h5>
                    </div>
                    <div className="main_container_choose_section_right">
                        <ContactForm 
                            heading = "Start your free trail today!"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChooseUs