import React from 'react'
import "./Button.css"


const Button = (props) => {
  return (
   <>
   <div className="main__button">
                <div className="button">
                  <div className="button__btn">{props.title}</div>
                </div>
            </div>
   </>
  )
}

export default Button