import React from 'react'
import { Spinner } from 'react-bootstrap'
import "./Loader.css"

const Loader = (props) => {
    return (
        <>
            <div className= {`${props.className}`}>
                <Spinner animation="border" variant="warning" />
            </div>
        </>
    )
}

export default Loader