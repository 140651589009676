import React from 'react'
import Heading from '../Heading/Heading'
import { serviceData } from '../../data/data'
import "./Services.css"
import SectionHeading from '../SectionHeading/SectionHeading'

const Services = () => {
    return (
        <>
            <section className="services" id="services">
            <div className='services_subheading'><SectionHeading title = "Benefits we provide" /></div>
                <div className='services_heading'> <Heading heading="Our Services" className="main_heading-black" /></div>
                <div className="box-container">
                    {
                        serviceData.map((item, index) =>

                            <div className="box" key={index}>
                                <div className='box-icon'>{item.icon}</div>
                                <h3>{item.title}</h3>
                                <p>{item.desc}</p>
                            </div>
                        )
                    }
                </div>
            </section>
        </>
    )
}

export default Services