import React, { useState, useEffect } from 'react'
import CourseCard from '../CourseCard/CourseCard'
import "./CourseCardGrid.css"
import { Tab, Tabs } from 'react-bootstrap'
import axios from 'axios'
import MyPagination from '../MyPagination/MyPagination'

const CourseCardGrid = () => {
  // const URL = "http://localhost:8000/api/v1"
  const URL = "https://al-wahid-server.netlify.app/.netlify/functions/server"
  const [courseData, setCourseData] = useState([]);
  const [loading, setLoading] = useState(false);

  // For Pagination
  const [currentPage, setCurrentPage] = useState(1);
  // const [postPerPage, setPostPerPage] = useState(8);
  const [postPerPage] = useState(12);

  // get Courses
  const getCourses = async () => {
    setLoading(true)
    await axios.get(`${URL}/all-courses`)
      .then((response) => {
        // console.log(response.data)
        setCourseData(response.data.Courses)
      })
      .catch((e) => {
        console.log("Getting course error", e)
      }).finally(()=>{
        setLoading(false)
      })
  }

  const filterAdult = courseData.filter((data) => {
    return data.category === "adult"
  })
  const filterOther = courseData.filter((data) => {
    return data.category === "other"
  })
  const filterChildren = courseData.filter((data) => {
    return data.category === "children"
  })
  useEffect(() => {
    getCourses()
  }, [])

  const toTop = (e) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const lastPostIndex = currentPage * postPerPage
  const firstPostIndex = lastPostIndex - postPerPage
  const currentPostChildren = filterChildren.slice(firstPostIndex, lastPostIndex)
  const currentPostAdult = filterAdult.slice(firstPostIndex, lastPostIndex)
  const currentPostOther = filterOther.slice(firstPostIndex, lastPostIndex)
  return (
    <>
      <Tabs
        defaultActiveKey="children"
        id="uncontrolled-tab-example"
        className="mb-3 main_tabs"
      >
        <Tab eventKey="children" title="Children Courses (3-15 years)">
          <div className="main_container_course_card">
            <CourseCard
              courseData={currentPostChildren}
              loading={loading}
            />
          </div>
          <MyPagination
            totalPosts={filterChildren.length}
            postPerPage={postPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            onClick = {toTop}
          />
        </Tab>
        <Tab eventKey="adult" title="Adult Courses">
          <div className="main_container_course_card">
            <CourseCard
              courseData={currentPostAdult}
              loading={loading}
            />
          </div>
          <MyPagination
            totalPosts={filterAdult.length}
            postPerPage={postPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            onClick = {toTop}
          />
        </Tab>

        <Tab eventKey="other" title="Other Courses">
          <div className="main_container_course_card">
            <CourseCard
              courseData={currentPostOther}
              loading={loading}
            />
          </div>
          <MyPagination
            totalPosts={filterOther.length}
            postPerPage={postPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </Tab>
      </Tabs>
    </>
  )
}

export default CourseCardGrid