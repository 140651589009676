import React from 'react'
import Heading from '../Heading/Heading'
import SectionHeading from '../SectionHeading/SectionHeading'

import "./AboutSection.css"
const AboutSection = () => {
    return (
        <>
            <section className="about__section">
                <div className="about__main__container">
                    <div className="about__main__container__right">
                        <img src="./images/logo.jpg" alt="about-section-img" className="about__main__container__right-img about-img" />
                    </div>
                    <div className="about__main__container__left">
                        <SectionHeading title="Our History" />
                        <Heading heading="About Al-Wahid Academy" />
                        <h5 className="about__main__container__left-text">Al Wahid Quranic Academy  is a leading Virtual Quran and Islamic Studies Institute, which provides One on One Live class to students around the globe.

                            Six Years of experience in online Teaching

                            Our courses are designed to meet all age group needs, including Children, Adults, and Professionals. Our tutor's panel consists of highly qualified, dedicated and skilled teachers. As a result our lessons are conducted in a very smooth, timely, and efficient manner that hence translates into ascending progress of our individual students.</h5>

                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutSection