import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { Loader } from '../index'
import "./BlogCard.css"



const BlogCard = ({blogData, loading}) => {
    // console.log(blogData)
    const myStyle = {
        height: "200px",
        width: "286px"
    }
    return (

        <>
            {
                loading ? <Loader className = "main_container_loader-margin" /> : blogData.length === 0 ? (<>
<div> <h5>No Blogs yet...</h5> </div>
                </>) : (
                    blogData.map((item, index) =>
                        <Card style={{ width: '18rem' }} className="blog_card" key={index}>
                            <Card.Img variant="top" src={item.image.myFile} style={myStyle} />
                            <Card.Body>
                                <Card.Title className='blog_card_content-heading'>
                                {item.title.length > 15 ? `${item.title.substring(0, 15)}...` : item.title}
                                </Card.Title>
                                <Card.Text className='blog_card_content-text' dangerouslySetInnerHTML = {{__html: item.description.length > 10 ? `${item.description.substring(0, 30)}...` : item.description}}>
                                </Card.Text>    
                                <Link to={`/blog-detail/${item._id}`} className='blog_card-content-btn'>Read More!</Link>
                            </Card.Body>
                        </Card>
                    )
                )
            }
        </>
    )
}

export default BlogCard