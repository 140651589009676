import React from 'react'
import Heading from '../Heading/Heading'
import "./BreadCrumb.css"

const BreadCrumb = (props) => {
  return (
    <>
      <div className="main_container_bread_section">
        <div className="main_container_bread_section_content">
          <Heading 
            className = "main_heading-white"
            heading = {props.heading} />
        </div>

      </div>
    </>
  )
}

export default BreadCrumb