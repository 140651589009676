import React from 'react'
import "./ContactDetail.css"
import ContactForm from '../ContactForm/ContactForm'
import Information from '../Information/Information'

const ContactDetail = () => {
    return (
        <>
            <div className="main_container_contact_details">
                <Information />
                <ContactForm 
                    heading = "Contact Details"
                />
            </div>
        </>
    )
}

export default ContactDetail